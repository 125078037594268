const Mdata = [
    {
      id: 1,
      cover: "../../../images/Media1.png",
      para: "Nersiol hair oil is designed as an absolutely non sticky formulation such that the hair oil completely transfers to hair from hand.",
      type: "News"
    },
    {
      id: 2,
      cover: "../../images/Media3.png",
      para: "During the Durgapuja of 2017 we partly sponsored a few Puja Pandals of kalkata and suburbs to reach to more and more customers with our brand Nersiol Light Hair Oil.",
      type: "Events"
    },
    {
      id: 3,
      cover: "../../images/Media2.png",
      para: "Our first product Nersiol Light Hair Oil with UV Protection was launched on 1st September 2017 at Spring Club Kolkata.",
      type: "News"
    },
    
  ]
  
  export default Mdata