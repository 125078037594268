import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Api from '../../../Api/Api';
import defaultImage from '../../../images/noImage-found.jpg';
import { Link } from "react-router-dom";
import { slugify } from "../../../utils/utils";
import './Productslider.css'
const ImageSlider = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Api.books().then(res => {
      if (res.status === "Success") {
        setProducts(res.data.slice(-10)); // Fetch the last 10 products
      }
    });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,  // Show 5 products at a time
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768, // Adjust this for mobile view
        settings: {
          slidesToShow: 2, // Show 2 products at a time on smaller screens
        },
      },
      {
        breakpoint: 1024, // Adjust this for tablets
        settings: {
          slidesToShow: 3, // Show 3 products at a time on tablets
        },
      },
      {
        breakpoint: 1200, // Adjust this for larger screens
        settings: {
          slidesToShow: 5, // Show 5 products on larger screens
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...sliderSettings}>
        {products.map((product, index) => (
          <div className="product-wrapper" key={index}>
            <Link
              to={`/${product?.categoryName}/${slugify(product?.ProductName)}`}
              state={{ data: product }}
            >
              <div className="product-item">
                <img
                  className="product-image"
                  src={product?.ProductImage || defaultImage}
                  alt={product.ProductName}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }}
                />
                <div className="product-info">
                  <p className="product-title">{product.ProductName}</p>
                  <figcaption className="blockquote-footer">
                    <cite>By {product?.AuthorName || 'EBC'}</cite>
                  </figcaption>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
