import React, { useState } from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    IconButton,
    Divider,
    Box,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Add, Remove, Close } from '@mui/icons-material';
import BillingAddressPage from './BillingAddressPage'; // Adjust the path based on your project structure
import Api from '../../Api/Api';
import { useSelector } from 'react-redux';
// import { FaPlus, FaMinus } from 'react-icons/fa'; // Import icons from react-icons library
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { addtocart } from '../../Store/Slice/Cartslice';
import Loader from '../../common/Loader/Loader';
import { useIsMobile } from '../../utils/utils'
import defaultImage from '../../images/noImage-found.jpg';
// Sample cart data with product images
const initialCartItems = [
    { id: 1, name: 'Product 1', quantity: 1, price: 100, image: 'https://via.placeholder.com/50' },
    { id: 2, name: 'Product 2', quantity: 2, price: 150, image: 'https://via.placeholder.com/50' },
];

const steps = ['Cart', 'Billing & Address', 'Payment'];

const CartComponent = () => {
    const data = useSelector((state) => state.cartdata.cartdata);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const isMobile = useIsMobile(); // Use the custom hook
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState(initialCartItems);
    const [activeStep, setActiveStep] = useState(0); // State to manage active step
    // Increment quantity
    const handleIncrement = async (itemdata) => {
        try {
            const productIndex = data.Products.findIndex(
                (existingProduct) => existingProduct.ProductID === itemdata.ProductID
            );

            if (productIndex !== -1) {
                const newData = JSON.parse(JSON.stringify(data)); // Deep copy
                newData.Products[productIndex].Qty += 1;

                setLoader(true);

                setTimeout(async () => {
                    const res = await Api.cart(newData);

                    if (res?.status == "Success") {
                        const resdata = res.data;
                        setLoader(false);
                        dispatch(addtocart({ datacart: resdata }));
                    } else {
                        setLoader(false);
                        toast.error("Oops! Something went wrong");
                    }
                }, 1000);
            }
        } catch (error) {
            setLoader(false);
            toast.error("Oops! Something went wrong");
        }
    };

    const handleDecrement = async (itemdata) => {
        try {
            const productIndex = data.Products.findIndex(
                (existingProduct) => existingProduct.ProductID === itemdata.ProductID
            );

            if (productIndex !== -1 && data.Products[productIndex].Qty > 0) {
                const newData = JSON.parse(JSON.stringify(data)); // Deep copy
                newData.Products[productIndex].Qty -= 1;
                setLoader(true);

                setTimeout(async () => {
                    const res = await Api.cart(newData);
                    console.log("res", res)

                    if (res?.status == "Success") {
                        const resdata = res.data;
                        setLoader(false);
                        dispatch(addtocart({ datacart: resdata }));
                    } else {
                        setLoader(false);
                        toast.error("Oops! Something went wrong");
                    }
                }, 1000);
            }
           
        } catch (error) {
            setLoader(false);
            toast.error("Oops! Something went wrong");
        }
    };

    const handleDelete = async (itemdata) => {
        try {

            let newData = JSON.parse(JSON.stringify(data)); // Deep copy

            // Filter out the product to be deleted
            // newData.Products = newData.Products.filter(item => item.ProductID !== ProductID);
            const indexToDelete = data.Products.findIndex(
                (existingProduct) => existingProduct.ProductID === itemdata.ProductID
            );

            // Remove the product by index
            if (indexToDelete !== -1) {
                newData.Products.splice(indexToDelete, 1);
            }
            //   console.log("newData",newData)
            //   return false
            setLoader(true)

            setTimeout(async () => {

                const res = await Api.cart(newData);

                if (res?.status == "Success") {
                    const resdata = res.data;
                    setLoader(false)

                    dispatch(addtocart({ datacart: resdata }));

                    // setTimeout(() => {
                    //     navigate("/add-to-cart");
                    // }, 2000);
                } else {
                    toast.error("Oops! Something went wrong");
                    setLoader(false)

                }

            }, 1000);


        } catch (error) {

            toast.error("Oops! Something went wrong");
            setLoader(false)

        }


    };


    // Remove item from cart
    const removeItem = (id) => {
        const updatedItems = cartItems.filter((item) => item.id !== id);
        setCartItems(updatedItems);
    };

    return (
        <>
            <Container
                style={{
                    marginTop: '30px',
                    marginBottom: '30px',
                    display: 'flex',
                    justifyContent: 'center', // Centering the content
                }}
            >
                {loader && <Loader />}
                <div
                    style={{
                        maxWidth: '1000px',
                        width: '100%',
                        // paddingLeft: '15px',  // Add left padding for gap
                        // paddingRight: '15px', // Add right padding for gap
                    }}
                >
                    {/* Progress Bar */}
                    <Stepper
                        activeStep={activeStep}
                        style={{ marginBottom: '20px', justifyContent: 'center' }} // Center the stepper
                    >
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {/* Cart and Order Summary Layout */}
                    {activeStep === 0 && (
                        <Grid container spacing={3} mt={5}>
                            {/* Left Side: Cart Items */}
                            <Grid item xs={12} md={8}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Cart ({data?.Products.length} Items)
                                        </Typography>
                                    </CardContent>
                                    {/* Cart Header */}
                                    <Grid container sx={{ backgroundColor: 'rgb(243, 244, 246)', padding: '11px' }}>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle1">Product</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Quantity</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="subtitle1">Price</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="subtitle1">Action</Typography>
                                        </Grid>
                                    </Grid>
                                    <CardContent>
                                        <hr />
                                        {/* Cart Items */}
                                        {data?.Products.map((item) => (
                                            <Grid container key={item.id} alignItems="center" mb={2}>
                                                <Grid item xs={5}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            src={item.ProductImage|| defaultImage}
                                                            alt={item?.ProductName}
                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevents infinite loop if the default image fails
                                                                e.target.src = defaultImage; // Set the fallback default image
                                                            }}
                                                            style={{ width: '50px', marginRight: '10px' }}
                                                        />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Typography>{item?.ProductName}</Typography>
                                                            <Typography>MRP: ₹{item?.Amount}</Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <IconButton onClick={() => handleDecrement(item)} color="secondary">
                                                        <Remove />
                                                    </IconButton>
                                                    <Typography component="span">{item?.Qty}</Typography>
                                                    <IconButton onClick={() => handleIncrement(item)} color="primary">
                                                        <Add />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography>₹{item?.SubTotal}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton onClick={() => handleDelete(item)} color="primary">
                                                        <Close />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Right Side: Order Summary */}
                            <Grid item xs={12} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Order Summary
                                        </Typography>
                                        <Divider style={{ margin: '20px 0' }} />
                                        <Typography>Subtotal: ₹{data?.Subtotal.toFixed(2)}</Typography>
                                        {data?.Discountamount && (
                                            <>
                                                <Divider style={{ margin: '10px 0' }} />
                                                <Typography>Discount: ₹{data?.Discountamount.toFixed(2)}</Typography>
                                            </>
                                        )}
                                        <Divider style={{ margin: '20px 0' }} />
                                        <Typography>Total: ₹{data?.Amount.toFixed(2)}</Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            style={{ marginTop: '20px', borderRadius: '20px' }}
                                            onClick={() => setActiveStep(1)} // Move to the billing address step
                                        >
                                            Checkout
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 1 && <BillingAddressPage setActiveStep={(val) => setActiveStep(val)} />}
                </div>
            </Container>

        </>
    );
};

export default CartComponent;
