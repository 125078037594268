import React, { useEffect, useState } from 'react';
import HomeSlider from '../Home/HomeSlider/HomeSlider';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import ImageSlider from '../Home/Product/Product.js';
import './Home.css';  // Import the CSS file for styling
import Api from '../../Api/Api'
import defaultImage from '../../images/noImage-found.jpg';
import { Link } from "react-router-dom"
import { slugify } from "../../utils/utils"
function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Api.books().then(res => {
   //   console.log("res=>", res);
      if (res.status === "Success") {
        setProducts(res.data.slice(0, 4));
      }
    });
  }, []);

  return (
    <>
      <HomeSlider />

      {products.length > 0 && (
       
        <div className="product-list">
          {products.map((product, index) => (
              <Link
       
              to= {`/${product?.categoryName}/${slugify(product?.ProductName)}` }
              state={{data: product}}
              className="blogItem-link"
            >
            <div key={product._id} className="product-card">
              <img
                src={product?.ProductImage || defaultImage}
                alt={product.ProductName}
                className="product-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage; // Fallback image
                }}
              />
              <div className="product-details">
                <h3>{product.ProductName}</h3>
                <p dangerouslySetInnerHTML={{ __html: product.productDescription }}></p>
                {/* <p className="product-price">Price: ₹{product.ProductPrice}</p> */}
                <figure>
                <blockquote class="blockquote">
                <p className="product-price">Price: ₹{product.ProductPrice}</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                  <cite title="Source Title"> <span className='text-align-end'>{product?.AuthorName || 'Unknown'}</span></cite>
                </figcaption>
              </figure>
                {/* <p className="product-price">Price: ₹{product.ProductPrice}</p>
                <p className="product-author">Author: {product?.AuthorName || 'Unknown'}</p>  */}
              </div>
            </div>
            </Link>
          ))}
        </div>
      
      )}
      <HomeAbout />
      <ImageSlider />
    </>
  );
}

export default Home;
