import React, { useState } from "react";
import Api from "../../Api/Api";
import { useDispatch } from "react-redux";
// import { Box, Button, Grid, Typography, TextField, Modal, Radio, RadioGroup, FormControlLabel, FormControl, Checkbox } from '@mui/material';

import { addtocart } from "../../Store/Slice/Cartslice";
import toast from "react-hot-toast";
import Loader from "../../common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  IconButton,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
const BillingAddressPage = ({ setActiveStep }) => {
  const data = useSelector((state) => state.cartdata.cartdata);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      fullName: "Gulshan Varma",
      phone: "123456789",
      address: "123 Street, City, Country",
      type: "Home",
    },
    {
      id: 2,
      fullName: "Nikhil",
      phone: "987654321",
      address: "456 Avenue, City, Country",
      type: "Office",
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddAddress = (newAddress) => {
    setAddresses([...addresses, newAddress]);
    handleCloseModal();
  };

  const handleSelectAddress = (id) => {
    setSelectedAddress(id);
  };

  const handleDeleteAddress = (id) => {
    setAddresses(addresses.filter((address) => address.id !== id));
  };
  
  const handleProceedToPayment = async () => {
    //setActiveStep(0); // Move to payment step
    // Move to payment step
    const orderData = {
      ...data,
      DeliveryAddress: {
        LineAddress1: addresses.find(user => user.id === selectedAddress).address,
        CompleteAddress: addresses.find(user => user.id === selectedAddress).address,
        Landmark: "",
        Type: "Home",
        Location: {
          lat: 0,
          lon: 0,
        },
      },
    };
    const res = await Api.createOrder(orderData);
    if (res?.status === "Success") {
      setLoader(true);
      toast.success("Your Order is Confirmed");
      // Clear the cart data in Redux
      dispatch(
        addtocart({
          datacart: {
            Products: [],
            Subtotal: 0,
            Amount: 0,
          },
        })
      );
      setLoader(false);
      navigate("/");
    } else {
      toast.error("Oops! Something went wrong");
      setLoader(false);
    }
  };

  return (
    <>
      {/* <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
                marginBottom: '30px',
            }}
        > */}
      {/* Left Side - Billing & Address */}
      {loader && <Loader />}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h5">Billing & Address</Typography>
              {/* <Button variant="contained" color="primary" onClick={handleOpenModal}>
                                Add New Address
                            </Button> */}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenModal}
                style={{ marginBottom: "10px" }}
              >
                Add New Address
              </Button>
            </Box>
            {/* Address List */}
            {addresses.map((address) => (
              <Box
                key={address.id}
                onClick={() => handleSelectAddress(address.id)}
                sx={{
                  border:
                    selectedAddress === address.id
                      ? "2px solid blue"
                      : "1px solid grey",
                  borderRadius: "5px",
                  padding: "16px",
                  marginBottom: "16px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Typography variant="h6">{address.fullName}</Typography>
                <Typography>{address.phone}</Typography>
                <Typography>{address.address}</Typography>
                <Typography variant="body2">{address.type}</Typography>
                {selectedAddress === address.id ? (
                  <CheckCircleIcon
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "blue",
                    }}
                  />
                ) : (
                  <DeleteIcon
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteAddress(address.id);
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Order Summary
              </Typography>
              <Divider style={{ margin: "20px 0" }} />
              <Typography>Total Items:₹{data?.Products.length}</Typography>
              <Divider style={{ margin: "20px 0" }} />
              <Typography>Subtotal:₹{data?.Subtotal.toFixed(2)}</Typography>
              {data?.Discountamount && (
                <>
                  {" "}
                  <Divider style={{ margin: "10px 0" }} />
                  <Typography>
                    Discountamount: {data?.Discountamount.toFixed(2)}
                  </Typography>
                </>
              )}
              <Divider style={{ margin: "20px 0" }} />
              <Typography>Total:₹{data?.Amount.toFixed(2)}</Typography>
              <Divider style={{ marginBottom: "20px" }} />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "20px", borderRadius: "20px" }}
                onClick={handleProceedToPayment}
              >
                Payment
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Right Side - Order Summary */}
      {/* <Box sx={{ width: '35%', border: '1px solid grey', padding: '16px', borderRadius: '8px' }}>
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
      
        <Button variant="contained" color="primary" fullWidth sx={{ borderRadius: '20px' }} onClick={handleProceedToPayment}>
          Proceed to Payment
        </Button>
      </Box> */}

      {/* Add New Address Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New Address
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup row defaultValue="Home">
              <FormControlLabel value="Home" control={<Radio />} label="Home" />
              <FormControlLabel
                value="Office"
                control={<Radio />}
                label="Office"
              />
            </RadioGroup>
          </FormControl>
          <TextField fullWidth label="Full Name" sx={{ my: 2 }} />
          <TextField fullWidth label="Phone Number" sx={{ mb: 2 }} />
          <TextField fullWidth label="Address" sx={{ mb: 2 }} />
          <TextField fullWidth label="City" sx={{ mb: 2 }} />
          <TextField fullWidth label="Country" sx={{ mb: 2 }} />
          <FormControlLabel
            control={<Checkbox />}
            label="Use this address as default"
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleAddAddress({
                  id: Date.now(),
                  fullName: "New Name",
                  phone: "1234567890",
                  address: "New Address",
                  type: "Home",
                })
              }
            >
              Deliver to this address
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* </Container> */}
    </>
  );
};

export default BillingAddressPage;
