import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Stepper,
  Button,
  Step,
  StepLabel,
  Divider,
  Paper,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Api from "../../Api/Api";
import {formatDate} from "../../utils/utils"

const steps = ["Order Confirmed", "Accepted", "Out for Delivery", "Delivered"];

const OrderItem = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [deliveryTimeline, setDeliveryTimeline] = useState([]);
  const { orderid } = useParams();
  const navigate = useNavigate();

  const getOrderItem = async () => {
    try {
      const res = await Api.getOrderDetails(orderid);
      setOrderDetails(res?.order);
      setDeliveryTimeline(res?.order.DeliveryTimeline);
    } catch (error) {
      console.log(error, "getting error in getOrderItem()");
    }
  };

  useEffect(() => {
    getOrderItem();
  }, []);
  const activeStep = deliveryTimeline.findIndex(item => item.UpdatedOn !== null);
  
  const onBack = () => {
    navigate(`/orders`)
  }

  return (
    <Box padding={3}>
    <Button variant="contained" startIcon={<ArrowBackIosIcon/>}sx={{marginBottom:'18px'}} onClick={onBack}>Back</Button>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        Order Details
      </Typography>

      <Typography variant="h6" gutterBottom>
        Order No. {orderDetails?.OrderNo}
      </Typography>

      <Grid container spacing={3} mb={10}>
        <Grid item xs={12} md={8}>
          <Card elevation={3} sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Delivery Address
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {orderDetails?.firstname}
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                {orderDetails?.DeliveryAddress.Type}
                <br />
                {orderDetails?.DeliveryAddress?.CompleteAddress}
              </Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                Phone number
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {orderDetails?.phoneNO}
              </Typography>
            </CardContent>
          </Card>

          <Paper elevation={3} sx={{ p: 3, bgcolor: "#fff" , width: "140%"}} >
            <Box mb={3}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {orderDetails?.DeliveryTimeline.map((label) => (
                  <Step key={label?._id} completed={!!label.UpdatedOn}>
                    <StepLabel>{label?.Status}</StepLabel>
                   {label?.UpdatedOn !== null && <Typography variant="body2" color="textSecondary">{formatDate(label?.UpdatedOn)}</Typography>} 
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Typography variant="h6" gutterBottom mb={2}>
              Product Details
            </Typography>
            {orderDetails?.Products?.map((product, index) => (
              <Box key={index} mb={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3} sm={2}>
                    <Box
                      component="img"
                      src={product.ProductImage}
                      alt={product.ProductName}
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "120px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={10}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {product.ProductName}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Qty: {product.Qty}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Price: ₹{product.ProductPrice}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Discount: ₹{product.Discountamount}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">
                          Amount: ₹{product.Amount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {index < orderDetails.Products.length - 1 && (
                  <Divider sx={{ my: 2 }} />
                )}
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="subtitle1" fontWeight="bold">
                Discount
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                ₹{orderDetails?.Discountamount}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="bold">
                Total
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                ₹{orderDetails?.Amount}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderItem;