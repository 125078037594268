// utils.js
import { useState, useEffect } from 'react';
export const slugify = (text) =>{
    //console.log("text",text)
 const path=text?text:""
 path
    .toString()
    .toLowerCase()
    .trim()
    // .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/\s+/g, '') // Replace spaces with dashes
    .replace(/[^\w-]+/g, ''); // Remove non-word characters except dashes
    return path;
}


    export  const useIsMobile = () => {
      const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
      useEffect(() => {
          const handleResize = () => {
              setIsMobile(window.innerWidth <= 768);
          };
          window.addEventListener('resize', handleResize);
  
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);
  
      return isMobile;
  };
  

  export   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };
  