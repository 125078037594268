import React from "react"
import { Link } from "react-router-dom"
//import Sdata from "./Sdata"
import { slugify } from "../../utils/utils"
import defaultImage from '../../images/noImage-found.jpg' ;

const Cards = ({ item = {}, categoryname = "" }) => {
  // Safely destructuring with default values
  const { _id, ProductName = "No Name", ProductImage = defaultImage, productDescription = "No Description", ProductPrice = 0 } = item;

  // Logging for debugging purposes
  // console.log("Product Name:", ProductName);
  // console.log("Category Name:", categoryname);

  const data = { ...item };

  return (
    <div className="items">
      {/* Image section */}
      <div className="img">
        <img src={ProductImage || defaultImage} alt={ProductName} 
           onError={(e) => {
            e.target.onerror = null; // Prevents infinite loop if the default image fails
            e.target.src = defaultImage; // Set the fallback default image
          }}
        />
        
        {/* Safe navigation link */}
        <Link
          // to={{
          //   // pathname: `/${categoryname ? slugify(categoryname) : "category"}/${slugify(ProductName)}`,
          //   pathname: `/${categoryname}/${slugify(ProductName)}`,
          //   state: { data: data }
          // }}
          to= {`/${categoryname}/${slugify(ProductName)}` }
          state={{data: data}}
          className="blogItem-link"
        >
          <i className="fas fa-external-link-alt"></i>
        </Link>
      </div>
      
      {/* Product title */}
      <div className="ptitle">
        <h3>{ProductName}</h3>
      </div>
      
      {/* Product description */}
      <div className="desc">
        {/* <p >{productDescription}</p> */}
        <p dangerouslySetInnerHTML={{ __html:productDescription }}></p>
        
      </div>
      
      {/* Product price */}
      <div className="price">
        <p>Price: ₹{ProductPrice}</p>
      </div>
    </div>
  );
};

export default Cards;
