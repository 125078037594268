import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Divider,
  TextField,
  Grid,
  Drawer,
  Paper,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import TablePagination from "@mui/material/TablePagination";
import Api from "../../Api/Api";
import { Close as CloseIcon } from "@mui/icons-material";

const OrdersList = ({ isMobile }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [count , setCount] = useState();
  const initialFilters = {
    page: 0,
    rowperpage: 10,
    DeliveryStatus: "",
    fromDate: "",
    toDate: "",
    createdOn: "",
    OrderNo: "",
    SearchOrderNo: "",
  };
  const [filters, setFilters] = useState(initialFilters);

  const handleChangePage = (event, newPage) => {
    setFilters((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters((prev) => ({ ...prev, rowperpage: parseInt(event.target.value, 10) }));
    setFilters((prev) => ({ ...prev, page: 0 }));
  };

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const query = new URLSearchParams({
        page: filters.page + 1,
        limit: filters.rowperpage,
        OrderNo: filters.OrderNo,
        fromDate: formatDate(filters.fromDate),
        toDate: formatDate(filters.toDate),
        createdAt: formatDate(filters.createdOn),
        DeliveryStatus: filters.DeliveryStatus,
      }).toString();
      const res = await Api.getOrders(query);
      setCount(res.total)
      setOrders(res.orders);
    } catch (error) {
      console.log("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [filters]);

  const handleOrderClick = (order) => {
    navigate(`/order/${order._id}`);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setIsDrawerOpen(false);
  };

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
    setIsDrawerOpen(false);
  };

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        {/* Sidebar Filters or Drawer for Mobile */}
        {!isMobile ? (
          <Grid item xs={12} md={3}>
            <Box padding={2} sx={{ border: "1px solid #ddd", borderRadius: 2 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Filters</Typography>
                <Button
                  sx={{ textTransform: "none", padding: "2px 5px",  borderRadius:"25px"}}
                  variant="contained"
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1">Delivery Status</Typography>
              {["Created", "Accepted", "Out for delivery", "Delivered"].map(
                (status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        name="DeliveryStatus"
                        value={status}
                        checked={filters.DeliveryStatus === status}
                        onChange={(e) =>
                          handleFilterChange("DeliveryStatus", e.target.value)
                        }
                      />
                    }
                    label={status}
                  />
                )
              )}
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" mb={2}>
                Date Range
              </Typography>
              <TextField
                label="From Date"
                type="date"
                name="fromDate"
                value={filters.fromDate}
                onChange={(e) => handleFilterChange("fromDate", e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="To Date"
                type="date"
                name="toDate"
                value={filters.toDate}
                onChange={(e) => handleFilterChange("toDate", e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
                sx={{ mt: 2 }}
              />

              <Divider sx={{ marginY: 2 }} />
              <Typography variant="subtitle1">Created On</Typography>
              <TextField
                label="createdOn"
                type="date"
                name="createdOn"
                value={filters.createdOn}
                onChange={(e) =>
                  handleFilterChange("createdOn", e.target.value)
                }
                size="small"
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}
                onClick={() => toggleDrawer(true)}
                sx={{ textTransform: "none", padding: "5px 15px" , borderRadius:"25px"}}
              >
                Filter
              </Button>
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={() => toggleDrawer(false)}
              >
                <Box width={250} padding={2}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    mb={3}
                  >
                    <IconButton onClick={() => toggleDrawer(false)} sx={{background:"#1976d2", borderRadius:"50%"}}>
                      <CloseIcon sx={{color:"white"}}/>
                    </IconButton>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="h6">Filters</Typography>
                    <Button
                      sx={{ textTransform: "none", padding: "3px" , borderRadius:"25px"}}
                      variant="contained"
                      onClick={resetFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Divider sx={{ marginY: 2 }} />
                  {["Created", "Accepted", "Out for delivery", "Delivered"].map(
                    (status) => (
                      <FormControlLabel
                        key={status}
                        control={
                          <Checkbox
                            name="DeliveryStatus"
                            value={status}
                            checked={filters.DeliveryStatus === status}
                            onChange={(e) =>
                              handleFilterChange(
                                "DeliveryStatus",
                                e.target.value
                              )
                            }
                          />
                        }
                        label={status}
                      />
                    )
                  )}
                  <Divider sx={{ marginY: 2 }} />
                  <Typography variant="subtitle1" mb={2}>
                    Date Range
                  </Typography>
                  <Grid item xs={12} md={12}>
                  <TextField
                    label="From"
                    type="date"
                    name="fromDate"
                    value={filters.fromDate}
                    onChange={(e) =>
                      handleFilterChange("fromDate", e.target.value)
                    }
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                  </Grid>

                  <Grid item xs={12} md={12} mt={1}>
                  <TextField
                    label="To"
                    type="date"
                    name="toDate"
                    value={filters.toDate}
                    onChange={(e) =>
                      handleFilterChange("toDate", e.target.value)
                    }
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginTop: 2 }}
                  />
                  </Grid>

                  <Divider sx={{ marginY: 2 }} />
                  <Typography variant="subtitle1">Created On</Typography>
                  <TextField
                    label="createdOn"
                    type="date"
                    name="createdOn"
                    value={filters.createdOn}
                    onChange={(e) =>
                      handleFilterChange("createdOn", e.target.value)
                    }
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginTop: 2 }}
                  />
                </Box>
              </Drawer>
            </Box>
          </Grid>
        )}

        {/* Orders Content */}
        <Grid item xs={12} md={9}>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              variant="outlined"
              placeholder="Type order number to search"
              sx={{ width: isMobile ? "75%" : "40%" }}
              name="OrderNo"
              value={filters.OrderNo}
              onChange={(e) => handleFilterChange("OrderNo", e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && fetchOrders()}
              InputProps={{
                endAdornment: filters.OrderNo && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => resetFilters()}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { height: "35px", padding: "9px" },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none", padding: "4px 6px", marginLeft: 2, borderRadius:"25px" }}
              onClick={fetchOrders}
            >
              {isMobile ? "Search" : "Search Orders"}
            </Button>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
                 {orders.length > 0 ? (
                orders.map((order) => (
                  <Grid item xs={12} key={order._id}>
                    <Paper
                      variant="outlined"
                      sx={{
                        padding: 2,
                        cursor: "pointer",
                        transition: "box-shadow 0.3s ease",
                        "&:hover": {
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        },
                        maxWidth: "100%",
                      }}
                      onClick={() => handleOrderClick(order)}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} sm={2}>
                          <Box
                            component="img"
                            src={order?.Products[0]?.ProductImage}
                            alt={order?.Products[0]?.ProductName}
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "120px",
                              objectFit: "contain",
                            }}
                              loading="lazy"
                          />
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography variant="subtitle1">
                            OrderNo: {order?.OrderNo}
                          </Typography>
                          <Typography variant="subtitle1">
                            Qty: {order?.Products.length}
                          </Typography>
                          <Typography variant="subtitle1" color="text.primary">
                            Amount: ₹{order?.Amount}
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Box
                              sx={{
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                bgcolor:
                                  order.DeliveryStatus === "Created"
                                    ? "red"
                                    : "green",
                                marginRight: 1,
                              }}
                            />
                            <Typography
                              variant="subtitle1"
                              color={order.statusColor}
                            >
                              {order?.DeliveryStatus} on{" "}
                              {order?.DeliveryUpdatedOn}
                            </Typography>
                          </Box>
                          {order?.DeliveryStatus === "Delivered" && (
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                            >
                              Your item has been delivered
                            </Typography>
                          )}
                          {order?.DeliveryStatus === "Cancelled" && (
                            <Typography variant="body2" color="text.secondary">
                              Shipment is cancelled
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign="center"
                  justifyContent="center"
                  mt={4}
                >
                  No orders found.
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={7} mt={2}>
        <Grid item xs={12} md={12}>
            <TablePagination
              component="div"
              count={count}
              page={filters.page}
              onPageChange={handleChangePage}
              rowsPerPage={filters.rowperpage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrdersList;
