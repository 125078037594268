import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  ButtonBase,
  Avatar,
  Popover,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.jpg";
import Api from "../../Api/Api";
import { logout } from "../../Store/Slice/AuthSlice";
import { slugify } from "../../utils/utils";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.auth.auth);
  const data = useSelector((state) => state.cartdata.cartdata);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    Api.categorylist().then((res) => {
      if (res.status === "Success") {
        setCategories(res.data);
      }
    });
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (category) => {
    navigate(`/book/${slugify(category.categoryName)}`, {
      state: { id: category._id },
    });
    handleMenuClose();
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#3f51b5" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img
              src={logo}
              alt="Brand Logo"
              style={{ width: "50px", height: "50px" }}
            />
          </Link>
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Button
            color="inherit"
            component={Link}
            to="/"
            sx={{ textTransform: "none" }}
          >
            Home
          </Button>

          {/* Dropdown Menu for Categories */}
          <Button
            color="inherit"
            onClick={handleMenuClick}
            sx={{ textTransform: "none" }}
          >
            Categories
          </Button>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
              {categories.map((category, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.categoryName}
                </MenuItem>
              ))}
            </Box>
          </Popover>

          {data?.Products.length > 0 && (
            <Button
              color="inherit"
              sx={{ textTransform: "none" }}
              component={Link}
              to="/cart"
              startIcon={<ShoppingCartIcon />}
            >
              Add to Cart
            </Button>
          )}

          {isLoggedIn && (
            <Button
              color="inherit"
              component={Link}
              to="/orders"
              sx={{ textTransform: "none" }}
              startIcon={<LocalMallIcon />}
            >
              My Orders
            </Button>
          )}

          {isLoggedIn ? (
            <Button
              color="inherit"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{ textTransform: "none" }}
            >
              Logout
            </Button>
          ) : (
            <Button
              color="inherit"
              component={Link}
              to="/login"
              sx={{ textTransform: "none" }}
            >
              Login
            </Button>
          )}
        </Box>

        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
          <Box width={250} padding={2}>
            <List
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <IconButton onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
                <ListItem component={NavLink} to="/" onClick={toggleDrawer}>
                  <ListItemText primary="Home" />
                </ListItem>
                {data?.Products.length > 0 && (
                  <ListItem
                    component={NavLink}
                    to="/cart"
                    onClick={toggleDrawer}
                  >
                    <ListItemText primary="Cart" />
                  </ListItem>
                )}
                <ListItem
                  component={NavLink}
                  to="/orders"
                  onClick={toggleDrawer}
                >
                  <ListItemText primary="My Orders" />
                </ListItem>

                {/* Categories in Drawer */}
                <ListItem component={ButtonBase} onClick={handleMenuClick}>
                  <ListItemText primary="Categories" />
                </ListItem>
                <Popover
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
                    {categories.map((category, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleCategoryClick(category);
                          setIsDrawerOpen(false);
                        }}
                      >
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Box>
                </Popover>
              </Box>
            </List>
            <Box sx={{ padding: "8px", borderTop: "1px solid #ccc" }}>
              {isLoggedIn && (
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    alt={user?.firstname}
                    src={user?.avatarUrl}
                    sx={{ marginRight: 1 }}
                  />
                  <span>{user?.firstname}</span>
                </Box>
              )}
              <hr style={{ margin: "8px 0" }} />
              {isLoggedIn ? (
                <ListItem component={ButtonBase} onClick={handleLogout}>
                  <ListItemText primary="Logout" />
                  <LogoutIcon sx={{ marginLeft: 1 }} />
                </ListItem>
              ) : (
                <ListItem
                  component={NavLink}
                  to="/login"
                  onClick={toggleDrawer}
                >
                  <ListItemText primary="Login" />
                </ListItem>
              )}
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
